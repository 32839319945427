 .entries td {
    overflow: hidden;
}

.entries td .progress {
    width: 100% !important;
}

.bib_number {
    width: 70px;
    text-align: center;
}

 .license_number {
     width: 100px;
     text-align: center;
 }

 .first_name {
     width: 150px;
 }

 .last_name {
     width: 150px;
 }

 .team {
     width: 200px;
 }

 .rank_value {
     width: 90px;
     text-align: center;
 }

 .delete {
     margin-right: 10px;
     color: #f5365c;
 }

 .save {
     margin-right: 10px;
     color: #2dce89;
 }